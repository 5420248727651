/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from 'lib/hooks';
import { UserAttributes } from 'lib/models';
import { DateTime } from 'luxon';

const profileData: UserAttributes = {
  balanceAvailable: 0,
  balanceBook: 0,
  balanceBookPayable: 0,
  balanceCredit: 0,
  balanceCreditMax: 0,
  balanceCurrent: 0,
  balanceFee: 0,
  balanceFeePayable: 0,
  balanceLimit: 0,
  balanceLimitMin: 0,
  balanceOverdue: false,
  balancePayable: 0,
  balanceTotal: 0,
  bankAccount: {
    accountNumberLastDigits: '',
    bankName: '',
  },
  createdAt: '',
  dob: '',
  email: '',
  firstName: '',
  id: '',
  lastName: '',
  mobileNumber: '',
  prettyDob: '',
  repaymentNextAmount: 0,
  repaymentNextAt: '',
  repaymentPlanId: '',
  status: 'inactive',
  statusReason: '',
  transactionLastAt: '',
  type: '',
};

export interface Profile {
  active: boolean;
  canRepay: boolean;
  canWithdraw: boolean;
  data: UserAttributes;
  inArrears: boolean;
  isLoading: boolean;
  minWithdrawAmount: number;
}

const initialState = {
  active: false,
  canRepay: false,
  canWithdraw: false,
  data: profileData,
  inArrears: false,
  isLoading: true,
  minWithdrawAmount: 20,
  prettyDob: '',
} as Profile;

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    resetProfile: () => initialState,
    refreshProfile: (state, action) => {
      const data = action.payload;

      state.data = {
        ...data,
        prettyDob: DateTime.fromISO(data.dob).toLocaleString(DateTime.DATE_MED),
      };

      state.active = data.status === 'active';
      state.canWithdraw = data.status === 'active' && !data.balanceOverdue && data.balanceAvailable >= 20;
      state.canRepay = data.balancePayable >= 20;
      state.inArrears = data.balanceOverdue && DateTime.fromISO(data.balanceOverdueAt) <= DateTime.now();
      state.isLoading = false;
    },
    updateLoadingFlag: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  actions: { resetProfile, refreshProfile, updateLoadingFlag },
  reducer: profileReducer,
} = profileSlice;

export const useProfileState = (): Profile => useAppSelector<Profile>((state) => state.profile);
